import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import {
    AccommodationOffer,
    FlightOffer,
    IChannelLikes,
    IGroup,
    IMessageOffer,
    Location,
} from '../components/chat/types';
import {
    ConversationMessages,
    Currency,
    Group,
    GroupUser,
    IOffersRequest,
    Partner,
    PartnerConfig,
    PartnerPlatformConfig,
    PartnerProfile,
    PartnerReport,
    PartnerSale,
    User,
} from './types';

const { publicRuntimeConfig } = getConfig();

interface UpdateGroupParams {
    group_name?: string;
}

interface AddGroupMemberParams {
    member_id?: string;
}

interface ChatEvent {
    type: string;
    properties?: Record<string, string>;
}

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers['X-Request-ID'] = uuidv4();
    return config;
});

export const api = {
    requestChatActivation: async (email: string, queryParams?: Record<string, string>) => {
        const url = `/rest/activations`;
        return axiosInstance.post(url, { email, tracking_params: queryParams });
    },

    getUserInfo: async (trackingParamsQuery?: string) => {
        let url = `/rest/users/me`;
        if (trackingParamsQuery) {
            url += `?${trackingParamsQuery}`;
        }
        const res = await axiosInstance.get<User>(url);
        const { data } = res;
        if (data.session_token) {
            setAuthorizationTokenHeader(data.session_token);
        }

        return res;
    },

    getUserGroups: async () => {
        const url = `/rest/groups`;
        return axiosInstance.get<Array<Group>>(url);
    },
    createGroup: async () => {
        const url = `/rest/groups`;
        return axiosInstance.post<Group>(url);
    },
    updateGroup: async (groupId: string, updateDetails: UpdateGroupParams) => {
        const url = `/rest/groups/${groupId}`;
        return axiosInstance.patch<Group>(url, updateDetails);
    },
    addGroupMember: async (groupId: string, updateDetails: AddGroupMemberParams) => {
        const url = `/rest/groups/${groupId}/members`;
        return axiosInstance.post<Group>(url, updateDetails);
    },
    removeGroupMember: async (groupId: string, memberId: string) => {
        const url = `/rest/groups/${groupId}/members/${memberId}`;
        return axiosInstance.delete<Group>(url);
    },

    deleteGroup: async (groupId: string) => {
        const url = `/rest/groups/${groupId}`;
        return axiosInstance.delete<Group>(url);
    },
    upsertGroup: async (offer: IMessageOffer) => {
        const url = `/rest/groups`;
        return axiosInstance.post<IGroup>(url, {
            offer_reference: offer,
        });
    },

    getGroupUsers: async (groupID: string) => {
        const url = `/rest/groups/${groupID}/users`;
        return axiosInstance.get<Array<GroupUser>>(url);
    },

    joinGroupByShareToken: async (shareToken: string) => {
        const url = `/rest/groups/${shareToken}/join`;
        return axiosInstance.post<Group>(url);
    },

    logout: async () => {
        const url = `/rest/logout`;
        return axiosInstance.post(url);
    },

    loginWithActivationCode: async (code: string) => {
        const url = `/rest/login`;
        return axiosInstance.post(url, { activation_code: code });
    },

    loginWithOAuthCode: async (
        facebookCode?: string,
        googleCode?: string,
        trackingParams?: Record<string, string>
    ) => {
        const url = `/rest/login`;
        return axiosInstance.post(url, {
            facebook_code: facebookCode,
            google_code: googleCode,
            tracking_params: trackingParams,
        });
    },

    getUserMessages: async (request: {
        offset: number;
        limit: number;
        createdBefore?: string;
        groupID?: string;
    }) => {
        let queryString = `offset=${request.offset}&limit=${request.limit}`;
        if (request.createdBefore) {
            queryString += `&created_before=${request.createdBefore}`;
        }
        if (request.groupID) {
            queryString += `&group_id=${request.groupID}`;
        }
        const url = `/rest/conversations/messages?${queryString}`;
        return axiosInstance.get<ConversationMessages>(url);
    },

    getChannelLikes: async (request: { channelHandle: string }) => {
        const url = `/rest/channels/${request.channelHandle}/likes`;
        return axiosInstance.get<IChannelLikes>(url);
    },

    getChannelMessages: async (request: {
        channelHandle: string;
        offset: number;
        limit: number;
        createdBefore?: string;
    }) => {
        let queryString = `offset=${request.offset}&limit=${request.limit}`;
        if (request.createdBefore) {
            queryString += `&created_before=${request.createdBefore}`;
        }
        const url = `/rest/channels/${request.channelHandle}/messages?${queryString}`;
        return axiosInstance.get<ConversationMessages>(url);
    },

    getHotelOffers: async (request: IOffersRequest) => {
        const url = `/rest/hotels/searches/${request.searchId}?offset=${request.offset}&limit=${request.limit}`;
        return axiosInstance.get<Array<AccommodationOffer>>(url);
    },

    getFlightOffers: async (request: IOffersRequest) => {
        const url = `/rest/flights/searches/${request.searchId}?offset=${request.offset}&limit=${request.limit}`;
        return axiosInstance.get<Array<FlightOffer>>(url);
    },

    // used on SSR pages and needs absolute URL
    getFlightOffer: async (id: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/flights/offers/${id}`;
        return axiosInstance.get<FlightOffer>(url);
    },

    // used on SSR pages and needs absolute URL
    getHotelOffer: async (id: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/hotels/offers/${id}`;
        return axiosInstance.get<AccommodationOffer>(url);
    },

    getLocationHints: async (letters: string, cancelToken: CancelToken, languageCode: string) => {
        const url = `/rest/autocomplete`;
        return axiosInstance.post<{ text: string; locations: Location[] }>(
            url,
            { q: letters, language_code: languageCode },
            { cancelToken: cancelToken }
        );
    },

    // used on SSR pages and needs absolute URL
    getPartnerConfig: async (partnerId: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerId}/config`;
        return axiosInstance.get<PartnerConfig>(url);
    },

    addFacebookPageToPartner: async (
        partnerId: string,
        pages: Record<string, string>,
        sessionToken: string
    ) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerId}/facebook`;
        return axiosInstance.post<PartnerPlatformConfig>(url, pages, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    trackChatEvent: async (request: { channelHandle: string; event: ChatEvent }) => {
        const url = `/rest/channels/${request.channelHandle}/events`;
        return axiosInstance.post(url, request.event);
    },

    requestPartnerLoginLink: async (email: string) => {
        const url = `/rest/partners`;
        return axiosInstance.post(url, { email });
    },

    loginWithPartnerActivationCode: async (code: string) => {
        const url = `/rest/partners/login`;
        return axiosInstance.post(url, { activation_code: code });
    },

    partnerLogout: async () => {
        const url = `/rest/partners/logout`;
        return axiosInstance.post(url);
    },

    getCurrentPartner: async (sessionToken?: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/me`;
        return axiosInstance.get<Partner>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getPartnerReports: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/reports`;
        return axiosInstance.get<Array<PartnerReport>>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getPartnerSales: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/sales`;
        return axiosInstance.get<Array<PartnerSale>>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getPartnerConfigClient: async (partnerId: string) => {
        const url = `/rest/partners/${partnerId}/config`;
        return axiosInstance.get<PartnerConfig>(url);
    },

    updatePartnerConfigClient: async (
        partnerId: string,
        partnerConfig: { config?: PartnerConfig; profile?: PartnerProfile }
    ) => {
        const url = `/rest/partners/${partnerId}`;
        return axiosInstance.post<{ config: PartnerConfig; profile: PartnerProfile }>(
            url,
            partnerConfig
        );
    },

    updatePartnerConfig: async (partnerId: string, partnerConfig: PartnerConfig) => {
        const url = `/rest/partners/${partnerId}/config`;
        return axiosInstance.patch<PartnerConfig>(url, partnerConfig);
    },

    getPartnerPlatforms: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/platforms`;
        return axiosInstance.get<PartnerPlatformConfig>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },
    getCurrencies: async () => {
        const url = `/rest/currencies`;
        return axiosInstance.get<Array<Currency>>(url);
    },
};

export const setPartnerIdHeader = (partnerId?: string) => {
    axiosInstance.defaults.headers.common['Partner-Id'] = partnerId;
};

export const setAuthorizationTokenHeader = (token: string) => {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const getAuthorizationTokenHeader = (): string | undefined => {
    const bearerToken = axiosInstance.defaults.headers.common['Authorization'] as
        | string
        | undefined;
    if (!bearerToken) {
        return undefined;
    }

    return bearerToken.split(' ')[1];
};
