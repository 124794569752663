import 'normalize.css';

import { captureException } from '@sentry/nextjs';
import { LoaderOverlay } from 'components/LoaderOverlay';
import { getI18nInstance } from 'lib/i18n';
import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';

import { GlobalStyle } from '../components/GlobalStyle';
import { GroupUpdater } from '../components/GroupUpdater';
import { ThemeStateProvider } from '../components/providers/ThemeStateProvider';
import { WidgetHandler } from '../components/WidgetHandler';
import { api, setPartnerIdHeader } from '../lib/api';
import { Provider, initializeStore, useHydrate } from '../lib/store';
import { PartnerConfig } from '../lib/types';
import { backfillPartnerConfig } from '../lib/widget';

const { publicRuntimeConfig } = getConfig();

const tagManagerArgs = {
    gtmId: publicRuntimeConfig?.googleTagManagerKey,
    auth: publicRuntimeConfig?.googleTagManagerAuth,
    preview: publicRuntimeConfig?.googleTagManagerPreview,
};

const TAGS = {
    TITLE: 'Eddy Travels - AI Travel Assistant',
    DESCRIPTION: 'Message Eddy Travels and find the cheapest flights and hotels in seconds!',
};

const isWidgetContext = (hostLocationPath: string): boolean => {
    const host = new URL(hostLocationPath).hostname;
    const widget = new URL(`${publicRuntimeConfig?.widgetHostUrl}`).hostname;

    return host === widget;
};

function MyApp({ Component, pageProps, router }: AppProps) {
    const store = useHydrate(pageProps.initialState);

    const { setConfig, partnerId, setPartnerId, languageCode } = store.getState();

    useEffect(() => {
        if (!partnerId) {
            return;
        }
        setPartnerIdHeader(partnerId);
    }, [partnerId]);
    const widgetThemeMessageHandler = async (message: {
        data: { type: string; configParams?: PartnerConfig; themeParams?: PartnerConfig };
    }) => {
        if (message?.data?.type === '__EDDY_TRAVELS_CHAT_LOAD_THEME') {
            const widgetParams = message.data.configParams || message.data.themeParams || {};
            const partnerConfig = backfillPartnerConfig(widgetParams);
            await setConfig(partnerConfig, true);
            await setPartnerId(partnerId);
            if (partnerConfig.partnerId) {
                setPartnerIdHeader(partnerConfig.partnerId);
            }
        }
    };

    useEffect(() => {
        if (!store.getState().isWidget) {
            TagManager.initialize(tagManagerArgs);
        }

        window.addEventListener('message', widgetThemeMessageHandler);

        // let widget know that we are ready to accept events
        window.parent.postMessage({ type: '__EDDY_TRAVELS_CHAT_READY' }, '*');

        return () => {
            window.removeEventListener('message', widgetThemeMessageHandler);
        };
    }, []);

    return (
        /* @ts-ignore */
        <Provider initialStore={store}>
            <ThemeStateProvider>
                <GlobalStyle />
                <Head>
                    <meta name="robots" content="noindex" />
                    <meta name="viewport" content="width=device-width, user-scalable=no" />
                    <meta property="og:type" content="website" />
                    <meta property="fb:app_id" content={publicRuntimeConfig?.facebookAppId} />
                    <title>{TAGS.TITLE}</title>
                    <meta key="og:title" property="og:title" content={TAGS.TITLE} />
                    <meta
                        key="og:description"
                        property="og:description"
                        content={TAGS.DESCRIPTION}
                    />
                    <meta name="description" content={TAGS.DESCRIPTION} />
                    <meta
                        key="og:image"
                        property="og:image"
                        content={`${publicRuntimeConfig?.rootUrl}/images/eddy-travels-assistant.jpg`}
                    />
                    <meta key="og:url" property="og:url" content={publicRuntimeConfig?.rootUrl} />
                </Head>
                <I18nextProvider i18n={getI18nInstance(languageCode)}>
                    <Component {...pageProps} />
                </I18nextProvider>
                <LoaderOverlay />
                <GroupUpdater />
                <WidgetHandler />
            </ThemeStateProvider>
        </Provider>
    );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
    const appProps = await App.getInitialProps(appContext);
    if (appContext.ctx.req) {
        const store = initializeStore();
        const state = store.getState();
        let host = appContext.ctx.req.headers.host;
        const { query: reqQuery } = appContext.ctx.req as unknown as {
            query: { [key: string]: string };
        };
        const language = reqQuery['language'];
        let partnerId = host?.substr(0, host.indexOf('.widget'));
        let isEmbedded = false;
        if (partnerId) {
            if (partnerId.startsWith('embed-')) {
                isEmbedded = true;
                partnerId = partnerId.substr('embed-'.length);
            }
            state.setPartnerId(partnerId);
            host = host?.substr(host.indexOf('widget.'), host.length - 1);
            try {
                const response = await api.getPartnerConfig(partnerId);
                state.setConfig(backfillPartnerConfig(response.data || {}), false);
                state.setLanguageCode(
                    language || response?.data?.defaultLanguageCode || state.languageCode
                );
            } catch (err) {
                if (err.response?.status !== 404) {
                    captureException(err);
                }
            }
        }
        const isWidget = isWidgetContext(`${(appContext.ctx.req as any).protocol}://${host}` || '');
        if (!isWidget) {
            state.setPartnerId('eddytravels');
        }
        state.setIsWidget(isWidget);
        state.setIsEmbedded(isEmbedded);
        appProps.pageProps['initialState'] = JSON.parse(JSON.stringify(store.getState()));
    }
    return {
        ...appProps,
    };
};

export default MyApp;
