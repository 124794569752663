import { CSSObject } from 'styled-components';

interface Props {
    css?: CSSObject;
}
export const Edit = (props: Props) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.1667 4.49999C16.3855 4.28112 16.6454 4.1075 16.9313 3.98905C17.2173 3.8706 17.5238 3.80963 17.8333 3.80963C18.1429 3.80963 18.4493 3.8706 18.7353 3.98905C19.0213 4.1075 19.2811 4.28112 19.5 4.49999C19.7189 4.71886 19.8925 4.97869 20.0109 5.26466C20.1294 5.55063 20.1903 5.85713 20.1903 6.16665C20.1903 6.47618 20.1294 6.78268 20.0109 7.06865C19.8925 7.35461 19.7189 7.61445 19.5 7.83332L8.24999 19.0833L3.66666 20.3333L4.91666 15.75L16.1667 4.49999Z"
            stroke="#231F20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
