import { useStore } from 'lib/store';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export const WidgetHandler = () => {
    const router = useRouter();
    const initialized = useStore((store) => store.isConfigInitialized);
    const previewImgUrl = useStore((store) => store.config.preview?.imgUrl);
    const [previewImgLoaded, setPreviewImgLoaded] = useState(false);
    const isPreview = router.route === '/preview';
    useEffect(() => {
        if (!initialized || !isPreview) {
            return;
        }
        if (previewImgLoaded) {
            window.parent.postMessage({ type: '__EDDY_TRAVELS_CHAT_LOAD_THEME_SUCCESS' }, '*');
        } else if (previewImgUrl) {
            const img = new Image();
            img.src = previewImgUrl;
            img.onload = () => setPreviewImgLoaded(true);
        }
    }, [initialized, router.route, previewImgLoaded]);
    useEffect(() => {
        if (!initialized || isPreview) {
            return;
        }
        window.parent.postMessage({ type: '__EDDY_TRAVELS_CHAT_LOAD_THEME_SUCCESS' }, '*');
    }, [initialized, router.route]);
    return null;
};
