import ar from './ar/ar.json';
import az from './az/az.json';
import de from './de/de.json';
import en from './en/en.json';
import es from './es/es.json';
import fr from './fr/fr.json';
import he from './he/he.json';
import hi from './hi/hi.json';
import it from './it/it.json';
import lt from './lt/lt.json';
import pt from './pt/pt.json';
import ru from './ru/ru.json';
import tr from './tr/tr.json';
import uk from './uk/uk.json';
import vi from './vi/vi.json';
import zh from './zh/zh.json';

export const CalendarLocales: Record<string, string> = {
    ar: 'ar-AE',
    az: 'az-AZ',
    de: 'de-DE',
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    he: 'he-IL',
    hi: 'hi-IN',
    it: 'it-IT',
    lt: 'lt-LT',
    pt: 'pt-PT',
    ru: 'ru-RU',
    uk: 'uk-UA',
    tr: 'tr-TR',
    vi: 'vi-VN',
    zh: 'zh-CN',
};

export default {
    ar: ar,
    az: az,
    de: de,
    en: en,
    es: es,
    fr: fr,
    he: he,
    hi: hi,
    it: it,
    lt: lt,
    pt: pt,
    ru: ru,
    uk: uk,
    tr: tr,
    vi: vi,
    zh: zh,
};
