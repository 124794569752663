import { useStore } from 'lib/store';
import React, { useEffect } from 'react';

import { useUserState } from './chat/hooks/UseGlobalState';

export const GroupUpdater = () => {
    const user = useUserState((state) => state.user);
    const updateUserGroups = useUserState((state) => state.updateUserGroups);
    const isWidget = useStore((store) => store.isWidget);
    useEffect(() => {
        const handler = () => {
            if (isWidget) {
                return;
            }
            if (user && !user.guest) {
                updateUserGroups();
            }
        };
        const intervalId = setInterval(handler, 30000);
        return () => clearInterval(intervalId);
    }, [user, updateUserGroups, isWidget]);
    return null;
};
