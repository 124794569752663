import { Loader as LoaderIcon } from 'components/icons';
import { transparentize } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const LoaderContainer = styled.div((props) => ({
    background: transparentize(0.5, props.theme.colors.text),
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    zIndex: 10000,
}));

const isUrlAbsolute = (url: string) => url.indexOf('://') > 0 || url.indexOf('//') === 0;

export const LoaderOverlay = () => {
    const [isVisible, showOverlay] = useState(false);
    useEffect(() => {
        const handleRouteChange = (event: MouseEvent) => {
            if (!event.composedPath) {
                return;
            }
            const pathElements = event.composedPath() as HTMLElement[];

            // since in some instances we use "react-native-web" links
            // we need this bit hacky way of finding all the links
            const externalLink =
                pathElements.filter((el) => {
                    const href = el.attributes?.getNamedItem('href');
                    return !!href && isUrlAbsolute(href.value);
                }).length > 0;

            if (externalLink) {
                showOverlay(true);
                setTimeout(() => {
                    showOverlay(false);
                }, 3000);
            }
        };

        document.addEventListener('click', handleRouteChange, { capture: true });
    }, []);

    const overlayClickHandler = () => {
        showOverlay(false);
    };

    return isVisible ? (
        <LoaderContainer onClick={overlayClickHandler}>
            <LoaderIcon />
        </LoaderContainer>
    ) : null;
};
