import { CSSObject } from 'styled-components';

interface Props {
    css?: CSSObject;
}
export const Undo = (props: Props) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M0.833344 3.33334V8.33334H5.83334"
                stroke="#54A855"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.92501 12.5C3.46534 14.0337 4.48946 15.3502 5.84306 16.2512C7.19666 17.1522 8.80641 17.5888 10.4298 17.4954C12.0531 17.402 13.6022 16.7835 14.8434 15.7331C16.0847 14.6828 16.9511 13.2575 17.3119 11.672C17.6727 10.0865 17.5084 8.42664 16.8439 6.94259C16.1793 5.45854 15.0504 4.23067 13.6274 3.44398C12.2043 2.65729 10.5641 2.35439 8.95391 2.58094C7.34372 2.80748 5.85077 3.55119 4.70001 4.70001L0.833344 8.33334"
                stroke="#54A855"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
