import { createGlobalStyle } from 'styled-components';

import { DefaultTheme } from '../themes/default';

export const GlobalStyle = createGlobalStyle`
    html,
    body {
        box-sizing: border-box;
        margin: 0;
        padding: 0px;
        min-height: 100vh;
        min-height: -webkit-fill-available;
        height: 100%;
        min-height: -moz-fit-content;
        background: #f9f9f9;
        -webkit-overflow-scrolling: touch;
    }
    @media (max-width: 600px) {
        body {
            overflow: hidden;
            padding-bottom: 0;
        }
    }
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
    button {
        border: none;
    }
    @font-face {
        font-family: ${DefaultTheme.fonts.default};
        font-weight: 400;
        ${DefaultTheme.fonts.defaultUrl ? `src: url('${DefaultTheme.fonts.defaultUrl}');` : ''}
    }
    @font-face {
        font-family: ${DefaultTheme.fonts.bold};
        font-weight: 500;
        ${DefaultTheme.fonts.boldUrl ? `src: url('${DefaultTheme.fonts.boldUrl}');` : ''}
    }
    @font-face {
        font-family: ${(props) => props.theme.fonts.default};
        font-weight: 400;
        ${(props) =>
            props.theme.fonts.defaultUrl ? `src: url('${props.theme.fonts.defaultUrl}');` : ''}
    @font-face {
        font-family: ${(props) => props.theme.fonts.bold};
        font-weight: 500;
        ${(props) => (props.theme.fonts.boldUrl ? `src: url('${props.theme.fonts.boldUrl}');` : '')}
    }
    @font-face {
        font-family: 'Eina01-Bold';
        src: url('/fonts/Eina01-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Eina01-SemiBold';
        src: url('/fonts/Eina01-SemiBold.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Eina01-Regular';
        src: url('/fonts/Eina01-Regular.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
`;
