import React from 'react';

export const RedHeart = ({ height = 16, width = 16 }: { height?: number; width?: number }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.2912 3.60999C19.7804 3.099 19.174 2.69364 18.5065 2.41708C17.8391 2.14052 17.1237 1.99817 16.4012 1.99817C15.6787 1.99817 14.9633 2.14052 14.2958 2.41708C13.6284 2.69364 13.0219 3.099 12.5112 3.60999L11.4512 4.66999L10.3912 3.60999C9.35949 2.5783 7.96022 1.9987 6.50118 1.9987C5.04215 1.9987 3.64288 2.5783 2.61118 3.60999C1.57949 4.64169 0.999893 6.04096 0.999893 7.49999C0.999893 8.95903 1.57949 10.3583 2.61118 11.39L3.67118 12.45L11.4512 20.23L19.2312 12.45L20.2912 11.39C20.8022 10.8792 21.2075 10.2728 21.4841 9.60535C21.7607 8.93789 21.903 8.22248 21.903 7.49999C21.903 6.77751 21.7607 6.0621 21.4841 5.39464C21.2075 4.72718 20.8022 4.12075 20.2912 3.60999Z"
            fill="#EB5757"
            stroke="#EB5757"
        />
    </svg>
);
