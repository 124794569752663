import { useTheme } from 'styled-components';

export const BrokenLink = () => {
    const theme = useTheme();
    return (
        <svg
            width="60"
            height="60"
            viewBox="0 0 59 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0)">
                <rect
                    x="-4.62054"
                    y="37.9737"
                    width="59.8033"
                    height="22.2995"
                    rx="11.1498"
                    transform="rotate(-39.4182 -4.62054 37.9737)"
                    fill={theme.colors.primary}
                />
                <path
                    d="M52.3684 6.6064C47.5183 1.79889 39.6224 1.79684 34.7702 6.6064L25.2379 16.0508C24.8324 16.4526 24.8324 17.1019 25.2379 17.5037C25.6434 17.9054 26.2988 17.9054 26.7042 17.5037L36.2365 8.05923C38.1861 6.1276 40.7901 5.06417 43.5683 5.06417C46.3485 5.06417 48.9514 6.12863 50.901 8.06026C52.8506 9.99189 53.925 12.5719 53.925 15.3255C53.925 18.0791 52.8506 20.658 50.901 22.5896L38.4371 34.9418C34.3948 38.9468 27.8139 38.9468 23.7705 34.9418C23.365 34.5401 22.7096 34.5401 22.3042 34.9418C21.8987 35.3435 21.8987 35.9929 22.3042 36.3946C24.7308 38.7979 27.9165 40 31.1033 40C34.29 40 37.4768 38.7979 39.9024 36.3946L52.3684 24.0425C54.71 21.7224 56.0001 18.6267 56.0001 15.3244C56.0001 12.0222 54.71 8.92641 52.3684 6.6064Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M29.997 44.2963L21.6256 52.641C19.6022 54.658 16.8997 55.7695 14.0152 55.7695C11.1308 55.7695 8.42933 54.658 6.40484 52.641C2.20949 48.459 2.20949 41.6517 6.40484 37.4687L18.5819 25.3303C22.7783 21.1483 29.6074 21.1505 33.8027 25.3303C34.2235 25.7498 34.9038 25.7498 35.3246 25.3303C35.7454 24.9108 35.7454 24.2328 35.3246 23.8133C30.2908 18.7934 22.096 18.7913 17.0601 23.8133L4.8819 35.9527C2.45165 38.3752 1.11383 41.6078 1.11383 45.0559C1.11383 48.5041 2.45273 51.7366 4.88298 54.1581C7.31322 56.5795 10.5561 57.9152 14.0142 57.9152C17.4744 57.9152 20.7173 56.5806 23.1464 54.1581L31.5178 45.8134C31.9386 45.3939 31.9386 44.7158 31.5178 44.2963C31.0969 43.8768 30.4178 43.8779 29.997 44.2963Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M37.8331 49.0152C37.2172 49.0152 36.7194 49.513 36.7194 50.129V56.8115C36.7194 57.4274 37.2172 57.9252 37.8331 57.9252C38.449 57.9252 38.9469 57.4274 38.9469 56.8115V50.129C38.9469 49.513 38.449 49.0152 37.8331 49.0152Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M42.8049 47.114C42.3694 46.6785 41.6655 46.6785 41.2301 47.114C40.7946 47.5495 40.7946 48.2534 41.2301 48.6889L45.9557 53.4134C46.1729 53.6306 46.458 53.7398 46.7431 53.7398C47.0283 53.7398 47.3134 53.6306 47.5306 53.4134C47.966 52.9779 47.966 52.274 47.5306 51.8386L42.8049 47.114Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M50.0844 42.3327H43.4018C42.7859 42.3327 42.2881 42.8305 42.2881 43.4464C42.2881 44.0623 42.7859 44.5602 43.4018 44.5602H50.0844C50.7003 44.5602 51.1981 44.0623 51.1981 43.4464C51.1981 42.8305 50.7003 42.3327 50.0844 42.3327Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M17.7855 12.2613C18.4014 12.2613 18.8993 11.7635 18.8993 11.1476V4.46504C18.8993 3.84914 18.4014 3.35129 17.7855 3.35129C17.1696 3.35129 16.6718 3.84914 16.6718 4.46504V11.1476C16.6718 11.7635 17.1707 12.2613 17.7855 12.2613Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M12.8126 14.1625C13.0298 14.3797 13.3149 14.4888 13.6001 14.4888C13.8852 14.4888 14.1703 14.3797 14.3875 14.1625C14.823 13.727 14.823 13.0231 14.3875 12.5877L9.66293 7.86311C9.22745 7.42763 8.52356 7.42763 8.08808 7.86311C7.65261 8.29859 7.65261 9.00248 8.08808 9.43796L12.8126 14.1625Z"
                    fill="black"
                    stroke="black"
                />
                <path
                    d="M5.53422 18.9438H12.2167C12.8327 18.9438 13.3305 18.446 13.3305 17.8301C13.3305 17.2142 12.8327 16.7163 12.2167 16.7163H5.53422C4.91832 16.7163 4.42047 17.2142 4.42047 17.8301C4.42047 18.446 4.91943 18.9438 5.53422 18.9438Z"
                    fill="black"
                    stroke="black"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="59" height="59" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
