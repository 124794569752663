export const Mail = () => {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 72 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.01893 35.2454C7.41041 31.385 9.52371 26.9847 13.5424 25.8268L55.0044 13.8801C60.2756 12.3613 65.6678 15.871 66.4129 21.3058L69.2719 42.16C69.9492 47.1007 66.4799 51.6503 61.536 52.3046L18.5 58L9.01893 35.2454Z"
                fill="#FEB001"
            />
            <path
                d="M4.73946 29.9954L35.2815 34.1079C35.6381 34.1049 36.1324 33.9746 36.5633 33.713L4.73946 29.9954ZM4.73946 29.9954C4.93749 29.7071 5.17722 29.4597 5.48524 29.2804L47.3926 4.88343L37.5497 32.6335C37.3223 33.0861 36.9825 33.457 36.5638 33.7127L4.73946 29.9954ZM50.8744 0.958058L50.8743 0.958026C50.2937 0.803003 49.7022 0.733661 49.1155 0.753236C48.1323 0.785939 47.1669 1.06402 46.2948 1.57166L46.4202 1.7872L46.2948 1.57167L3.76683 26.3292L3.7668 26.3292C1.8257 27.4596 0.74108 29.52 0.750055 31.6292L0.750055 31.6293C0.754477 32.6492 1.01641 33.6807 1.55634 34.6244L1.55638 34.6245L13.892 56.1667H8.2918C7.34765 56.1667 6.58342 56.9309 6.58342 57.875C6.58342 58.819 7.34765 59.5832 8.2918 59.5832H63.7083C64.6525 59.5832 65.4167 58.819 65.4167 57.875C65.4167 56.9309 64.6525 56.1667 63.7083 56.1667H40.1878L68.2334 39.8401C70.1754 38.7097 71.2593 36.6493 71.2499 34.5404C71.2455 33.5208 70.9837 32.4895 70.4436 31.5463L54.5559 3.79831C54.5559 3.79828 54.5558 3.79825 54.5558 3.79823C53.7481 2.38632 52.4402 1.37704 50.8744 0.958058ZM40.7233 33.9055L51.0324 4.84093C51.2546 5.02667 51.4451 5.24054 51.5897 5.49344L51.5898 5.49356L67.4775 33.2432L67.4776 33.2434C68.2088 34.5179 67.7737 36.1528 66.5148 36.8862L33.3972 56.1665H17.8305L4.82575 33.4544L34.9997 37.5096L35.0128 37.5113L35.0259 37.5117C35.0781 37.5132 35.1311 37.5132 35.1786 37.5132H35.1797C36.3017 37.5132 37.3921 37.2089 38.3404 36.6306C39.2967 36.048 40.0689 35.2113 40.5824 34.2056C40.6389 34.111 40.6859 34.0112 40.7233 33.9055Z"
                fill="black"
                stroke="black"
                strokeWidth="0.5"
            />
        </svg>
    );
};
