import i18n from 'i18next';

import locales from '../locales';

export const defaultNS = 'chat';
export const resources = {
    ar: { ...locales.ar },
    az: { ...locales.az },
    de: { ...locales.de },
    en: { ...locales.en },
    es: { ...locales.es },
    fr: { ...locales.fr },
    he: { ...locales.he },
    hi: { ...locales.hi },
    it: { ...locales.it },
    lt: { ...locales.lt },
    pt: { ...locales.pt },
    ru: { ...locales.ru },
    uk: { ...locales.uk },
    tr: { ...locales.tr },
    vi: { ...locales.vi },
    zh: { ...locales.zh },
} as const;

export const getI18nInstance = (
    defaultLanguage:
        | 'ar'
        | 'az'
        | 'de'
        | 'en'
        | 'es'
        | 'fr'
        | 'he'
        | 'hi'
        | 'it'
        | 'lt'
        | 'pt'
        | 'ru'
        | 'uk'
        | 'tr'
        | 'vi'
        | 'zh'
        | string = 'en'
) => {
    i18n.init({
        resources: resources,
        lng: defaultLanguage,
        fallbackLng: 'en',
        defaultNS: 'chat',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
            wait: false,
        },
    });

    return i18n;
};
