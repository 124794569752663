// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
    dsn: publicRuntimeConfig?.publicSentryDSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: publicRuntimeConfig?.sentryTracingRate / 100,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: ['ResizeObserver loop limit exceeded'], // https://github.com/petyosi/react-virtuoso/issues/254
});
