import i18n from 'i18next';
import { useLayoutEffect } from 'react';
import create from 'zustand';
import createContext from 'zustand/context';

import { TextDirection } from '../components/chat/types';
import { PartnerConfig } from './types';

let store: any;

interface PartnerConfigStateProps {
    isWidget: boolean;
    config: PartnerConfig;
    isConfigInitialized: boolean;
    isEmbedded: boolean;
    partnerId: string;
    languageCode: string;
    textDirection: TextDirection;
}

export interface PartnerConfigState extends PartnerConfigStateProps {
    setIsWidget: (isWidget: boolean) => void;
    setConfig: (partnerConfig: PartnerConfig, initialized: boolean) => void;
    setIsEmbedded: (isEmbedded: boolean) => void;
    setPartnerId: (partnerId: string) => void;
    setLanguageCode: (languageCode: string) => void;
}

const RTL_LANGUAGES = ['he', 'ar'];

const initialState: PartnerConfigStateProps = {
    isWidget: false,
    isConfigInitialized: false,
    config: {
        icons: {
            logoUrl: '/images/eddy-travels-brand.svg',
            botIconUrl: '/images/eddy-mascot.svg',
            headerIconUrl: '',
        },
        preview: {
            imgUrl: 'images/preview-beach.jpg',
            title: '🏖 Get travel deals',
            deeplink: '',
        },
        botName: 'AI Assistant',
        botTag: 'Assistant',
        privacyPolicyUrl: 'https://eddyai.com/privacy-policy',
        hidePoweredBy: false,
        hideCloseButton: false,
    },
    languageCode: 'en',
    isEmbedded: false,
    partnerId: '',
    textDirection: 'ltr',
};
export const initializeStore = (preloadedState = {}) => {
    return create<PartnerConfigState>((set, get) => ({
        ...initialState,
        ...preloadedState,
        setIsWidget: (isWidget: boolean) => {
            set({
                isWidget,
            });
        },
        setConfig: (config, isConfigInitialized) => {
            const currentConfig = get().config;
            if (!config.preview) {
                config.preview = currentConfig.preview;
            }
            if (!config.icons) {
                config.icons = currentConfig.icons;
            }
            if (!config.colors) {
                config.colors = currentConfig.colors;
            }
            if (!config.borderRadius) {
                config.borderRadius = currentConfig.borderRadius;
            }
            if (!config.fonts) {
                config.fonts = currentConfig.fonts;
            }
            return set({
                config: {
                    ...currentConfig,
                    icons: {
                        ...currentConfig.icons,
                        ...config.icons,
                    },
                    preview: {
                        ...currentConfig.preview,
                        ...config.preview,
                    },
                    borderRadius: {
                        ...currentConfig.borderRadius,
                        ...config.borderRadius,
                    },
                    colors: {
                        ...currentConfig.colors,
                        ...config.colors,
                    },
                    fonts: {
                        ...currentConfig.fonts,
                        ...config.fonts,
                    },
                    privacyPolicyUrl: config.privacyPolicyUrl || currentConfig.privacyPolicyUrl,
                    botName: config.botName || currentConfig.botName,
                    botTag: config.botTag || currentConfig.botTag,
                    hidePoweredBy: config.hidePoweredBy || currentConfig.hidePoweredBy,
                    hideCloseButton: config.hideCloseButton || currentConfig.hideCloseButton,
                },
                isConfigInitialized: isConfigInitialized,
            });
        },
        setIsEmbedded: (isEmbedded: boolean) => {
            set({
                isEmbedded,
            });
        },
        setPartnerId: (partnerId: string) => {
            set({
                partnerId,
            });
        },
        setLanguageCode: (languageCode: string) => {
            i18n.changeLanguage(languageCode);
            set({
                languageCode,
                textDirection: RTL_LANGUAGES.includes(languageCode) ? 'rtl' : 'ltr',
            });
        },
    }));
};

export function useHydrate(state: typeof initialState) {
    let _store = store ?? initializeStore(state);

    // For SSR & SSG, always use a new store.
    if (typeof window !== 'undefined') {
        // For CSR, always re-use same store.
        if (!store) {
            store = _store;
        }

        // And if initialState changes, then merge states in the next render cycle.
        useLayoutEffect(() => {
            if (state && store) {
                store.setState({
                    ...store.getState(),
                    ...state,
                });
            }
        }, [state]);
    }

    return _store;
}

export const { Provider, useStore } = createContext<PartnerConfigState>();
