import { captureException } from '@sentry/minimal';
import { api } from 'lib/api';
import { Group, GroupUser, User } from 'lib/types';
import { DateTime } from 'luxon';
import create, { State } from 'zustand';

import { ConversationItem, ILikes, MessageOfferType } from '../types';

interface IChatInputState extends State {
    input: string;
    shouldSend?: boolean;
    setInput: (value: string, sendMessage?: boolean) => void;
    setShouldSend: (send: boolean) => void;
}

export const useChatInputState = create<IChatInputState>(
    (set): IChatInputState => ({
        input: '',
        shouldSend: false,
        setInput: async (value: string, sendMessage?: boolean) =>
            set({ input: value, shouldSend: sendMessage }),
        setShouldSend: async (send: boolean) => set({ shouldSend: send }),
    })
);

interface IMessagesState extends State {
    currentChatHandle: string;
    messages: Array<ConversationItem>;
    offset: number;
    createdBefore: string;
    firstItemIndex: number;
    loadMessages: (chatHandle: string) => void;
    appendMessages: (messages: Array<ConversationItem>) => void;
    prependMessages: (messages: Array<ConversationItem>) => void;
    clearMessages: () => void;
}

const MESSAGES_PAGE_SIZE = 40;
const MESSAGE_MAX_INDEX = 100000;

export const useMessagesState = create<IMessagesState>(
    (set, get): IMessagesState => ({
        messages: [],
        offset: 0,
        createdBefore: '',
        firstItemIndex: MESSAGE_MAX_INDEX,
        currentChatHandle: '',
        loadMessages: async (chatHandle: string) => {
            const { clearMessages, currentChatHandle } = get();
            if (currentChatHandle !== chatHandle) {
                await clearMessages();
                await set({ currentChatHandle: chatHandle });
            }
            const { createdBefore, offset, firstItemIndex } = get();
            if (createdBefore && offset < MESSAGES_PAGE_SIZE) {
                return;
            }
            const response = await api.getChannelMessages({
                offset: offset,
                limit: MESSAGES_PAGE_SIZE,
                createdBefore: createdBefore,
                channelHandle: chatHandle,
            });
            if (!createdBefore) {
                set({ createdBefore: DateTime.local().toUTC().toISO() });
            }
            const newMessages = response.data.messages;
            if (newMessages && newMessages.length > 0) {
                await set((state) => ({
                    offset: offset + newMessages.length,
                    messages: newMessages.concat(state.messages),
                    firstItemIndex: firstItemIndex - state.messages.length,
                }));
            }
        },
        appendMessages: async (messages: Array<ConversationItem>) =>
            set((state) => ({ messages: state.messages.concat(messages) })),
        prependMessages: async (messages: Array<ConversationItem>) =>
            set((state) => ({ messages: messages.concat(state.messages) })),
        clearMessages: async () =>
            set({ messages: [], offset: 0, createdBefore: '', firstItemIndex: MESSAGE_MAX_INDEX }),
    })
);

interface IGroupState extends State {
    users: Array<GroupUser>;
    setGroupUsers: (users: Array<GroupUser>) => void;
}

export const useGroupState = create<IGroupState>(
    (set): IGroupState => ({
        users: [],
        setGroupUsers: async (users: Array<GroupUser>) => set({ users }),
    })
);

interface IUserState extends State {
    user?: User;
    groups: Array<Group>;
    login: (trackingParamsQuery?: string) => Promise<User | undefined>;
    logout: () => void;
    updateUserGroups: () => void;
}

export const useUserState = create<IUserState>(
    (set): IUserState => ({
        user: undefined,
        groups: [],
        login: async (trackingParamsQuery?: string) => {
            try {
                const response = await api.getUserInfo(trackingParamsQuery);
                const groupsResponse = await api.getUserGroups();
                set({ user: response.data, groups: groupsResponse.data });
                return response.data;
            } catch (e) {
                await captureException(e);
            }
        },
        logout: async () => {
            await api.logout();
            set({ user: undefined });
        },
        updateUserGroups: async () => {
            try {
                const groupsResponse = await api.getUserGroups();
                set({ groups: groupsResponse.data });
            } catch (e) {
                await captureException(e);
            }
        },
    })
);

interface IChannelLikesState extends State {
    carRentals: ILikes;
    flights: ILikes;
    accommodation: ILikes;
    activities: ILikes;
    updateChannelLikes: (channel: string) => void;
    like: (type: MessageOfferType, offerID: string, userID: string) => void;
    unlike: (type: MessageOfferType, offerID: string, userID: string) => void;
}

export const useChannelLikesState = create<IChannelLikesState>(
    (set, get): IChannelLikesState => ({
        carRentals: {},
        flights: {},
        accommodation: {},
        activities: {},
        updateChannelLikes: async (channel: string) => {
            const response = await api.getChannelLikes({ channelHandle: channel });
            await set({
                carRentals: response.data.car_rentals,
                flights: response.data.flights,
                accommodation: response.data.accommodation,
                activities: response.data.activities,
            });
        },
        like: async (type: MessageOfferType, offerID: string, userID: string) => {
            const key = getStateKey(type);
            // @ts-ignore
            await set((state) => {
                let likes: ILikes = state[key];
                if (!likes) {
                    likes = {};
                }
                const offerLikes = likes[offerID];
                if (!offerLikes) {
                    likes[offerID] = [userID];
                } else if (!offerLikes.includes(userID)) {
                    likes[offerID] = likes[offerID].concat([userID]);
                }
                return {
                    [key]: { ...likes },
                };
            });
        },
        unlike: async (type: MessageOfferType, offerID: string, userID: string) => {
            const key = getStateKey(type);
            const likes: ILikes = get()[key];
            if (likes?.[offerID]?.includes(userID)) {
                likes[offerID] = likes[offerID].filter((currentUserId) => currentUserId !== userID);
                // @ts-ignore
                await set({
                    [key]: { ...likes },
                });
            }
        },
    })
);

export const getStateKey = (type: MessageOfferType) => {
    switch (type) {
        case MessageOfferType.Accommodation:
            return 'accommodation';
        case MessageOfferType.CarRental:
            return 'carRentals';
        case MessageOfferType.Flights:
            return 'flights';
        case MessageOfferType.Activity:
            return 'activities';
    }
};

interface IRequireRegistrationState extends State {
    requireRegistration: boolean;
    setRequireRegistration: (requireRegistration: boolean) => void;
}

export const useRequireRegistrationState = create<IRequireRegistrationState>(
    (set): IRequireRegistrationState => ({
        requireRegistration: true,
        setRequireRegistration: async (requireRegistration: boolean) =>
            set({ requireRegistration: requireRegistration }),
    })
);

interface IScrollToBottomState extends State {
    scrollToBottom: boolean;
    setScrollToBottom: (scrollToBottom: boolean) => void;
}

export const useScrollToBottomState = create<IScrollToBottomState>(
    (set): IScrollToBottomState => ({
        scrollToBottom: false,
        setScrollToBottom: async (scrollToBottom: boolean) => set({ scrollToBottom }),
    })
);

interface IUseShowPromoState extends State {
    showPromo: boolean;
    setShowPromo: (showPromo: boolean) => void;
}

export const useShowPromoState = create<IUseShowPromoState>(
    (set): IUseShowPromoState => ({
        showPromo: true,
        setShowPromo: async (showPromo: boolean) => set({ showPromo }),
    })
);
