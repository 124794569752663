import { PartnerConfig } from './types';

// eslint-disable-next-line complexity
export const backfillPartnerConfig = (partnerConfig: PartnerConfig) => {
    // initializing empty values
    if (!partnerConfig.colors) {
        partnerConfig.colors = {};
    }
    if (!partnerConfig.preview) {
        partnerConfig.preview = {};
    }
    if (!partnerConfig.icons) {
        partnerConfig.icons = {};
    }
    // setting default colors
    if (partnerConfig.primaryColor && !partnerConfig.colors.primary) {
        partnerConfig.colors.primary = partnerConfig.primaryColor;
    }
    if (partnerConfig.colors.primary && !partnerConfig.colors.flightCardBackground) {
        partnerConfig.colors.flightCardBackground = partnerConfig.colors.primary;
    }
    if (partnerConfig.colors.primary && !partnerConfig.colors.priceTagBackground) {
        partnerConfig.colors.priceTagBackground = partnerConfig.colors.primary;
    }
    // backfilling with deprecated widget params if new ones arent present
    if (partnerConfig.activePrimaryColor && partnerConfig.colors.primaryActive) {
        partnerConfig.colors.primaryActive = partnerConfig.colors.primary;
    }
    if (partnerConfig.lightPrimaryColor && !partnerConfig.colors.primaryLight) {
        partnerConfig.colors.primaryLight = partnerConfig.lightPrimaryColor;
    }
    if (partnerConfig.botIconUrl && !partnerConfig.icons.botIconUrl) {
        partnerConfig.icons.botIconUrl = partnerConfig.botIconUrl;
    }
    if (partnerConfig.previewImgUrl && !partnerConfig.preview.imgUrl) {
        partnerConfig.preview.imgUrl = partnerConfig.previewImgUrl;
    }
    if (partnerConfig.previewTitle && !partnerConfig.preview.title) {
        partnerConfig.preview.title = partnerConfig.previewTitle;
    }
    if (partnerConfig.deeplink && !partnerConfig.preview.deeplink) {
        partnerConfig.preview.deeplink = partnerConfig.deeplink;
    }
    return partnerConfig;
};
