import { useStore } from 'lib/store';
import { PartnerConfig } from 'lib/types';
import React, { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as NativeThemeProvider } from 'styled-components/native';
import { DefaultTheme } from 'themes/default';
import { Theme } from 'themes/theme';

const getStyledTheme = (currentTheme: Theme, partnerConfig?: PartnerConfig) => {
    return {
        ...currentTheme,
        colors: {
            ...currentTheme.colors,
            ...partnerConfig?.colors,
        },
        fonts: {
            ...currentTheme.fonts,
            ...partnerConfig?.fonts,
        },
        borderRadius: {
            ...currentTheme.borderRadius,
            ...partnerConfig?.borderRadius,
        },
    };
};

export const ThemeStateProvider = (props: { children: ReactNode }) => {
    const theme = useStore((store) => store.config);
    const [styledTheme, setStyledTheme] = useState(getStyledTheme(DefaultTheme, theme));
    useEffect(() => {
        setStyledTheme(getStyledTheme(DefaultTheme, theme));
    }, [theme]);
    return (
        <ThemeProvider theme={styledTheme}>
            <NativeThemeProvider theme={styledTheme}>{props.children}</NativeThemeProvider>
        </ThemeProvider>
    );
};
