import { Theme } from './theme';

const profileColors = [
    {
        avatarColor: '#63BBEC',
        bubbleColor: '#E8F5FC',
    },
    {
        avatarColor: '#9397F8',
        bubbleColor: '#F2F2FE',
    },
    {
        avatarColor: '#81D8A6',
        bubbleColor: '#EFFAF4',
    },
    {
        avatarColor: '#D881DA',
        bubbleColor: '#FCF3FC',
    },
    {
        avatarColor: '#FF8388',
        bubbleColor: '#FFF3F4',
    },
];

export const DefaultTheme: Theme = {
    name: 'DEFAULT',
    breakpoints: {
        xs: '0px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
    },
    colors: {
        primary: '#FEB001',
        primaryLight: '#FFF2D9',
        primaryActive: '#EBA200',
        flightCardBackground: '#FEB001',
        priceTagBackground: '#FEB001',
        hover: '#EBA200',
        disabled: '#E6E6E6',
        text: '#231F20',
        textLight: '#FFFFFF',
        error: '#EC4025',
        background: '#FFFFFF',
        darkgrey: '#7C8B99',
        grey: '#E9EEF2',
        lightgrey: '#F9F9F9',
        darkergrey: '#D1DCE5',
        icon: '#000000',
    },
    fonts: {
        default: `Rubik, Arial, Helvetica, "Trebuchet MS", Tahoma`,
        bold: `Rubik, Arial, Helvetica, "Trebuchet MS", Tahoma`,
    },
    borderRadius: {
        quickReplies: '20px',
        cards: '10px',
        likes: '50px',
        botBubble: '10px 10px 10px 2px',
        userBubble: '10px 10px 2px 10px',
    },
    getProfileColor: (groupUserIndex: number) => {
        if (groupUserIndex < 0) {
            return profileColors[0];
        }
        return profileColors[groupUserIndex % (profileColors.length - 1)];
    },
};
