export const Facebook = () => {
    return (
        <svg
            width="11"
            height="19"
            viewBox="0 0 11 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.88544 0.00395327L7.42159 0C4.65354 0 2.86471 1.8353 2.86471 4.67593V6.83184H0.387416C0.173349 6.83184 0 7.00539 0 7.21946V10.3431C0 10.5572 0.173547 10.7306 0.387416 10.7306H2.86471V18.6126C2.86471 18.8267 3.03805 19 3.25212 19H6.48428C6.69835 19 6.8717 18.8265 6.8717 18.6126V10.7306H9.76823C9.98229 10.7306 10.1556 10.5572 10.1556 10.3431L10.1568 7.21946C10.1568 7.11667 10.1159 7.01824 10.0434 6.9455C9.97083 6.87276 9.872 6.83184 9.76921 6.83184H6.8717V5.00424C6.8717 4.12583 7.08102 3.6799 8.22528 3.6799L9.88504 3.67931C10.0989 3.67931 10.2723 3.50576 10.2723 3.29189V0.391374C10.2723 0.177699 10.0991 0.0043486 9.88544 0.00395327Z"
                fill="white"
            />
        </svg>
    );
};
