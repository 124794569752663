import { GroupUser, User } from 'lib/types';

export interface Button {
    label?: string;
    url?: string;
}
export interface Link {
    Label: string;
    URL: string;
}
export interface Hint {
    type: number;
    label: string;
    tracking_id: string;
}
export interface Carousel {
    images: Image[];
    fallback: Image;
}
export interface CarHireIncludedMileage {
    unlimited: boolean;
    unit: string;
    included: number;
}
export interface CarHireAdditionalDrivers {
    free?: number;
    paid_price?: number;
    original_price?: number;
}
export interface CarHireInsurance {
    theft_protection: boolean;
    third_party_cover: boolean;
    free_collision_waiver: boolean;
    free_damage_refund: boolean;
}
export interface CarHireFuel {
    diesel: string;
    policy: string;
    fair: boolean;
}
export interface CarHireValueAdd {
    fuel: CarHireFuel;
    free_equipments: string[];
    insurance: CarHireInsurance;
    young_driver_surcharge: boolean;
    one_way_surcharge: boolean;
    free_cancellation: boolean;
    free_breakdown_assistance: boolean;
    additional_drivers: CarHireAdditionalDrivers;
    included_mileage: CarHireIncludedMileage;
}
export interface CarHireProvider {
    id: string;
    name: string;
    image_url: string;
}
export interface CarHireOffer {
    id: string;
    sipp: string;
    currency: string;
    total_price: number;
    seats: string;
    doors: string;
    bags: string;
    manual: boolean;
    air_conditioning: boolean;
    mandatory_chauffeur: boolean;
    provider: CarHireProvider;
    vehicle: string;
    image_url: string;
    car_class: string;
    value_add: CarHireValueAdd;
    booking_url: string;
    deeplink_url: string;
    duration_days: number;
    free_cancellation: boolean;
    average_rating: number;
    num_ratings: number;
    title: string;
    subtitle: string;
}
export interface CarSearchQuery {
    PickUpLocation: Location;
    DropOffLocation: Location;
    PickUpTime: string;
    DropOffTime: string;
}
export interface CarHireSearchResult {
    ID: string;
    Query?: CarSearchQuery;
    Currency: string;
    OffersFound: number;
    MinTotalPrice: number;
    SelectedOffers: CarHireOffer[];
    SearchURL: string;
    SearchImageURL: string;
    SearchDuration: number;
}
export interface AccommodationOption {
    name: string;
    rank: number;
}
export enum AccommodationDealType {
    Sale = 'Sale',
    BlackFriday = 'BlackFriday',
}
export interface AccommodationDeal {
    original_price: number;
    name: string;
    discount_percentage: number;
    deal_type: AccommodationDealType;
}
export interface AccommodationOffer {
    id: string;
    external_id: string;
    amenities: string[];
    provider: string;
    type: string;
    room_type: string;
    name: string;
    distance: number;
    duration_days: number;
    rating: number;
    review_count: number;
    stars: number;
    images: string[];
    rooms: number;
    currency: string;
    price_per_night: number;
    total_price: number;
    booking_url: string;
    deeplink_url: string;
    options: AccommodationOption[];
    thumbnail_url: string;
    deal?: AccommodationDeal;
    title: string;
    subtitle: string;
    duration_label: string;
    rating_label: string;
}
export enum LocationType {
    Unknown = 0,
    Country = 1,
    State = 2,
    City = 3,
    Airport = 4,
    Pin = 5,
}
export interface Location {
    Type: LocationType;
    Lat: number;
    Lon: number;
    IATA?: string;
    ISO?: string;
    Name: string;
    NameEn: string;
    Flag?: string;
    State?: {
        Name: string;
    };
    City?: Location;
    Country?: Location;
    MainCity?: Location;
}

export interface AccommodationSearchQuery {
    Location: Location;
    CheckInDate: string;
    CheckOutDate: string;
}
export interface AccommodationSearchResult {
    ID: string;
    Query: AccommodationSearchQuery;
    UserID: string;
    Currency: string;
    OffersFound: number;
    MinPricePerNight: number;
    SelectedOffers: AccommodationOffer[];
    SearchURL: string;
    SearchImageURL: string;
    SearchDuration: number;
}

export interface ActivitySearchQuery {
    Location: Location;
    Activities: number[];
}

export interface ActivityOffer {
    id: string;
    title: string;
    subtitle: string;
    rating: number;
    price: number;
    price_description: string;
    booking_url: string;
    duration: string;
    thumbnail_url: string;
    images?: string[];
    location?: Location;
    currency: string;
    num_ratings: number;
    best_seller: boolean;
    price_label: string;
    rating_label: string;
}
export interface ActivitySearchResult {
    ID: string;
    Query: ActivitySearchQuery;
    UserID: string;
    Currency: string;
    OffersFound: number;
    MinPrice: number;
    SelectedOffers: ActivityOffer[];
    SearchDuration: number;
}
export interface Flight {
    Number: string;
    Carrier: Carrier;
    Origin: AirportInfo;
    Destination: AirportInfo;
    Duration: number;
    DepartureTime: string;
    ArrivalTime: string;
}
export interface Segment {
    Flight: Flight;
    FlightNo: string;
    Airline: string;
    OperatingFlightNo: string;
    OperatingCarrier: string;
    Origin: string;
    Destination: string;
    DepartureTime: string;
    ArrivalTime: string;
    Duration: number;
}
export interface AirportInfo {
    Code: string;
    Name: string;
    City: string;
    CityCode: string;
    CountryCode: string;
}
export interface Carrier {
    Name: string;
    Code: string;
    ImageURL: string;
}
export interface Leg {
    Carrier: Carrier;
    Airlines: string[];
    Origin: AirportInfo;
    Destination: AirportInfo;
    Segments: Segment[];
    DepartureTime: string;
    ArrivalTime: string;
    Duration: number;
}
export interface FlightOffer {
    id: string;
    ProviderName: string;
    OutboundLeg: Leg;
    InboundLeg: Leg;
    Tags: string[];
    CabinClass: string;
    AdultPassengers: number;
    ChildrenPassengers: number;
    InfantPassengers: number;
    Currency: string;
    Price: number;
    BookingToken: string;
    DeeplinkURL: string;
    BookingURL: string;
    DetailsURL: string;
    OverviewURL: string;
    ThumbnailURL: string;
    TelegramImgURL: string;
    AgentName: string;
    title: string;
    subtitle: string;
    tag_label: string;
}
export interface FlightSearchQuery {
    AdultPassengers: number;
    DepartureDate: TimeRange;
    ReturnDate: TimeRange;
    OriginName: string;
    DestinationName: string;
    OriginNameEn: string;
    DestinationNameEn: string;
}
export interface FlightSearchResult {
    ID: string;
    Query: FlightSearchQuery;
    UserID: string;
    Currency: string;
    OffersFound: number;
    MinPrice: number;
    SelectedOffers: FlightOffer[];
    SearchURL: string;
    SearchImageURL: string;
    SearchDuration: number;
    FlexSource: string;
    Error: any;
}
export interface LatLon {
    Lat: number;
    Lon: number;
}
export interface TimeRange {
    End: string;
    ExactDates: false;
    ExactHours: false;
    Start: string;
}
export interface Place {
    id: string;
    name: string;
    title: string;
    subtitle: string;
    type: string;
    rating: number;
    price_level: number;
    directions_url: string;
    distance: number;
    open_close_time: string;
    images?: string[];
    location?: LatLon;
}
export enum ActionType {
    URL = 0,
    Postback,
    Share,
}
export interface Action {
    Type: ActionType;
    Title: string;
    URL: string;
    Payload: string;
}
export interface Image {
    tracking_id: string;
    tracking_label: string;
    sponsored_content: boolean;
    title: string;
    subtitle: string;
    url: string;
    aspect_ratio: AspectRatio;
    default_action: Action;
    actions: Action[];
}
export interface Correction {
    original: string;
    replacement: string;
}

export enum Expectation {
    Any = 0,
    Date,
    DateTime,
    Location,
}

export enum AspectRatio {
    Horizontal,
    Square,
    FourThree,
}

export interface IBotMessage {
    tracking_id?: string;
    text?: string;
    gif?: string;
    image?: Image;
    places?: Place[];
    flight_search_result?: FlightSearchResult;
    accommodation_search_result?: AccommodationSearchResult;
    activity_search_result?: ActivitySearchResult;
    car_hire_search_result?: CarHireSearchResult;
    carousel?: Carousel;
    hints?: Hint[];
    expectation?: Expectation;
    links?: Link[];
    actions?: Action[];
    is_add?: boolean;
    message_type?: MessageType;
}

export interface AudioClip {
    URL: string;
    Format: number;
}
export interface LatLon {
    Lat: number;
    Lon: number;
}
export interface Correction {
    original: string;
    replacement: string;
}
export interface Interpretation {
    Intent: string;
    corrections?: Correction[];
}

export enum MessageType {
    Text,
    Audio,
    Deeplink,
    SharedSearch,
    Like,
    Unlike,
    Read,
    JoinedChannel,
    LeftChannel,
    StoryMention,
    SetLanguage,
}

export interface IUserMessage {
    message_id: string;
    message_type: MessageType;
    user_id: string;
    click: boolean;
    text?: string;
    deeplink?: string;
    interpretation: Interpretation;
    received_at: string;
    pin?: LatLon;
    audio_clip?: AudioClip;
    assistant_disabled: boolean;
    offer?: IMessageOffer;
}

export type TextDirection = 'rtl' | 'ltr';
export interface ConversationItem {
    id: string | number;
    user_id: string;
    user?: IUserMessage;
    bot?: IBotMessage;
    created_at?: string;
    isLastMessageInGroup?: boolean;
    isCarousel: boolean;
    hasAvatar: boolean;
}

export interface IBubble {
    message: ConversationItem;
    nextMessage?: ConversationItem;
    user?: User;
    groupUsers?: Array<GroupUser>;
    sendMessage: (message: string) => void;
    isLastMessage: boolean;
    direction: TextDirection;
}

export interface IChatBubble {
    message: ConversationItem;
    sendMessage: (message: string, options: ISendMessageOptions) => void;
    groupUsers?: Array<GroupUser>;
    index: number;
    direction: TextDirection;
}

export type IMessageProcessor = (currentMessage: ConversationItem) => void;

export interface IChatFeed {
    messages: Array<ConversationItem>;
    messageProcessors?: Array<IMessageProcessor>;
    sendMessage: (text: string, options?: ISendMessageOptions) => void;
    user?: User;
    groupUsers?: Array<GroupUser>;
    channelHandle?: string;
}

export interface IGroup {
    id: string;
    created_at: string;
    share_token: string;
    users: string[];
}

export interface ISendMessageOptions {
    assistant_disabled?: boolean;
    click?: boolean;
    message_type?: MessageType;
    offer?: IMessageOffer;
    tagged_users?: Array<string>;
    partner_id?: string;
}

export enum MessageOfferType {
    Accommodation,
    Flights,
    CarRental,
    Activity,
}

export interface IMessageOffer {
    id: string;
    type: MessageOfferType;
}

export interface IChannelLikes {
    id: string;
    car_rentals: ILikes;
    flights: ILikes;
    accommodation: ILikes;
    activities: ILikes;
}

export interface ILikes {
    [key: string]: string[];
}
