import { CSSObject } from 'styled-components';

interface Props {
    css?: CSSObject;
}
export const Share = (props?: Props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={props?.css}
    >
        <path
            d="M4.00002 12V20C4.00002 20.5304 4.21073 21.0391 4.5858 21.4142C4.96087 21.7893 5.46958 22 6.00002 22H18C18.5304 22 19.0392 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V12"
            stroke="#231F20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16 6L12 2L7.99998 6"
            stroke="#231F20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 2V15"
            stroke="#231F20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
