import React from 'react';

export const Heart = ({
    height = 16,
    width = 16,
    color = '#000000',
}: {
    height?: number;
    width?: number;
    color?: string;
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.1233 2.99308L21.1237 2.99349C21.5893 3.45883 21.9586 4.01132 22.2106 4.61942C22.4625 5.22753 22.5922 5.87931 22.5922 6.53755C22.5922 7.19578 22.4625 7.84756 22.2106 8.45567C21.9586 9.06377 21.5893 9.61627 21.1237 10.0816L21.1235 10.0818L19.9169 11.2884L11.9445 19.2608L3.97208 11.2884L2.76544 10.0818C1.82545 9.14181 1.29736 7.8669 1.29736 6.53755C1.29736 5.20819 1.82545 3.93328 2.76544 2.99329C3.70544 2.05329 4.98035 1.52521 6.3097 1.52521C7.63906 1.52521 8.91396 2.05329 9.85396 2.99329L11.0606 4.19993C11.5488 4.68808 12.3402 4.68808 12.8284 4.19993L14.035 2.99329L14.0352 2.99308C14.5005 2.52753 15.053 2.15822 15.6611 1.90625C16.2692 1.65429 16.921 1.5246 17.5793 1.5246C18.2375 1.5246 18.8893 1.65428 19.4974 1.90625C20.1055 2.15822 20.658 2.52753 21.1233 2.99308Z"
            stroke={color}
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
