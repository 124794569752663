export const Loader = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
                margin: 'auto',
                display: 'block',
                shapeRendering: 'auto',
                animationPlayState: 'running',
                animationDelay: '0s',
            }}
            width="150px"
            height="150px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <g
                transform="translate(25 50)"
                style={{
                    animationPlayState: 'running',
                    animationDelay: '0s',
                }}
            >
                <circle
                    cx="0"
                    cy="0"
                    r="6"
                    fill="#ffffff"
                    style={{
                        animationPlayState: 'running',
                        animationDelay: '0s',
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.3333333333333333s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                            animationPlayState: 'running',
                            animationDelay: '0s',
                        }}
                    />
                </circle>
            </g>
            <g
                transform="translate(50 50)"
                style={{
                    animationPlayState: 'running',
                    animationDelay: '0s',
                }}
            >
                <circle
                    cx="0"
                    cy="0"
                    r="6"
                    fill="#ffffff"
                    style={{
                        animationPlayState: 'running',
                        animationDelay: '0s',
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.16666666666666666s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                            animationPlayState: 'running',
                            animationDelay: '0s',
                        }}
                    />
                </circle>
            </g>
            <g
                transform="translate(75 50)"
                style={{
                    animationPlayState: 'running',
                    animationDelay: '0s',
                }}
            >
                <circle
                    cx="0"
                    cy="0"
                    r="6"
                    fill="#ffffff"
                    style={{
                        animationPlayState: 'running',
                        animationDelay: '0s',
                    }}
                >
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="0s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                            animationPlayState: 'running',
                            animationDelay: '0s',
                        }}
                    />
                </circle>
            </g>
        </svg>
    );
};
