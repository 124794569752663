import React from 'react';

export const Exit = ({ color = '#000000' }: { color?: string }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.97126 8L14.7988 13.8276C15.0671 14.0958 15.0671 14.5306 14.7988 14.7988C14.5306 15.0671 14.0958 15.0671 13.8276 14.7988L8 8.97126L2.17242 14.7988C1.90421 15.0671 1.46936 15.0671 1.20116 14.7988C0.932948 14.5306 0.932948 14.0958 1.20116 13.8276L7.02874 8L1.20116 2.17242C0.932948 1.90421 0.932948 1.46936 1.20116 1.20116C1.46936 0.932948 1.90421 0.932948 2.17242 1.20116L8 7.02874L13.8276 1.20116C14.0958 0.932948 14.5306 0.932948 14.7988 1.20116C15.0671 1.46936 15.0671 1.90421 14.7988 2.17242L8.97126 8Z"
            fill={color}
            stroke={color}
            strokeWidth="1.2"
        />
    </svg>
);
